'use client';

import { useParams } from 'next/navigation';
import { useEffect } from 'react';
import { useData } from '@/components/context/data-context';
import { getData } from '@/lib/server';
export default function DataFetcher() {
  const {
    data,
    setData
  } = useData();
  const {
    cid
  } = useParams<{
    cid: string;
  }>();
  useEffect(() => {
    const fetchData = async () => {
      if (!data && cid) {
        try {
          const fetchedData = await getData(cid);
          setData(fetchedData);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      }
    };
    void fetchData();
  }, [data, cid, setData]);
  return null;
}